<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-row>
        <v-col cols="12" sm="3">
          <v-card flat outlined>
            <v-toolbar flat dense>
              <v-spacer></v-spacer>
              <v-toolbar-title>My Profile Picture</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text style="padding: 0">
              <v-img :src="userPix()" contain min-height="200"></v-img>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="primary"
                small
                text
                @click.stop="pixDialog = true"
              >
                Update Picture
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <UpdatePicture
            :pixDialog="pixDialog"
            @closePix="closePix"
            @submitPix="submitPix"
          />
        </v-col>
        <v-col cols="12" sm="9">
          <v-card flat outlined class="myaccount">
            <v-toolbar flat dense>
              <v-spacer></v-spacer>
              <v-toolbar-title>My Profile Data</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="profile">
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>
                      <ul>
                        <li>Name:</li>
                        <li>
                          {{ myself.salutations | capitalize }}
                          {{ myself.first_name }}
                          {{ myself.mid_name | capitalize }}
                          {{ myself.last_name | capitalize }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>Gender:</li>
                        <li>{{ myself.gendersName }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>Marital:</li>
                        <li>{{ myself.marital_status_name }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>Birth Date:</li>
                        <li>{{ myself.date_of_birth | myDate }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>contact:</li>
                        <li>{{ myself.mobile_phone }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>last Updated:</li>
                        <li>{{ myself.updated | myDating }}</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <div style="margin-bottom: 5px"></div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="primary"
                small
                text
                @click.stop="personalDialog = true"
              >
                update personal data
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <UpdatePersonal
            :personalDialog="personalDialog"
            @closePersonal="closePersonal"
            @submitPersonal="submitPersonal"
          />
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils";
import UpdatePicture from "../../components/accounts/picture.vue";
import UpdatePersonal from "../../components/accounts/personal.vue";

export default {
  components: {
    UpdatePicture,
    UpdatePersonal,
  },
  mixins: [WinScroll("position"), Util],
  data() {
    return {
      tab: null,
      pixDialog: false,
      personalDialog: false,
      crumbs: [
        {
          exact: true,
          link: true,
          text: this.capitalize(localStorage.getItem("user.name")),
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      myself: {},
      pix: require("../../assets/images/my_user_black.png"),
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    isMedics: function () {
      let subtype = localStorage.getItem("user.subscriber_type");
      if (subtype > 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.find();
  },
  methods: {
    mySubscription(event) {
      console.log("data after child handle: ", event);
    },
    userPix() {
      let p = this.myself.my_pix;
      if (p === "") {
        return this.pix;
      } else {
        return p;
      }
    },
    closePix(p) {
      console.log(p);
      this.pixDialog = p.state;
    },
    submitPix(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts
        .myPicture(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
          localStorage.setItem("user.pix", p.data.picture);
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.pixDialog = p.state;
    },
    find() {
      this.loading = true;
      let self = this;
      Restful.accounts
        .find()
        .then((response) => {
          console.log(response.data);
          this.myself = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closePersonal(p) {
      console.log(p);
      this.personalDialog = p.state;
    },
    submitPersonal(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts
        .updateProfile(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.personalDialog = p.state;
    },
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
